<template>
    <div class="modal" :class="{ 'active': show }" v-if="mutablePerson">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modifier une personne</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form class="form">
                        <div class="form-group row">
                            <div class="row col-sm-5">
                                <label for="firstname" class="col-sm-3 col-form-label">Prénom</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="firstname" name="firstname" v-model="mutablePerson.firstname">
                                </div>
                            </div>
                            <div class="row col-sm-5">
                                <label for="lastname" class="col-sm-3 col-form-label">Nom</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="lastname" name="lastname" v-model="mutablePerson.lastname">
                                </div>
                            </div>
                            <div class="row col-sm-2">
                                <label class="col-sm-5 col-form-label">Sexe</label>
                                <div class="col-sm-7">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" value="Female" v-model="mutablePerson.gender">
                                        <label class="custom-control-label" for="customRadio1">Female</label>
                                    </div>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" value="Male" v-model="mutablePerson.gender">
                                        <label class="custom-control-label" for="customRadio2">Male</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="row col-sm-6">
                                <label for="email" class="col-sm-2 col-form-label">Email</label>
                                <div class="col-sm-10">
                                    <input type="email" class="form-control" id="email" name="email" v-model="mutablePerson.contact.email">
                                </div>
                            </div>
                            <div class="row col-sm-6">
                                <label for="phone" class="col-sm-6 col-form-label">Numéro de téléphone</label>
                                <div class="col-sm-6">
                                    <input type="tel" class="form-control" id="phone" name="phone" v-model="mutablePerson.contact.phone">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="row col-sm-6">
                                <label for="address" class="col-sm-2 col-form-label">Adresse</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="address" name="address" v-model="mutablePerson.contact.address">
                                </div>
                            </div>
                            <div class="row col-sm-3">
                                <label for="city" class="col-sm-3 col-form-label">Ville</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="city" name="address" v-model="mutablePerson.contact.city">
                                </div>
                            </div>
                            <div class="row col-sm-3">
                                <label for="country" class="col-sm-3 col-form-label">Pays</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="country" name="country" v-model="mutablePerson.contact.country">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="row col-sm-4">
                                <label for="fruit" class="col-sm-4">Fruit préféré</label>
                                <select class="form-control col-sm-8" id="fruit" v-model="mutablePerson.preferences.favorite_fruit">
                                    <option v-for="fruit in fruits" :key="fruit" :value="fruit">{{ fruit }}</option>
                                </select>
                            </div>
                            <div class="row col-sm-4">
                                <label for="color" class="col-sm-4">Couleur préférée</label>
                                <select class="form-control col-sm-8" id="color" v-model="mutablePerson.preferences.favorite_color">
                                    <option v-for="color in colors" :key="color" :value="color">{{ color }}</option>
                                </select>
                            </div>
                            <div class="row col-sm-4">
                                <label for="pet" class="col-sm-4">Animal préféré</label>
                                <select class="form-control col-sm-8" id="pet" v-model="mutablePerson.preferences.favorite_pet">
                                    <option v-for="pet in pets" :key="pet" :value="pet">{{ pet }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="row col-sm-8">
                                <label for="movie" class="col-sm-4 col-form-label">Film préféré</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" id="movie" name="movie" v-model="mutablePerson.preferences.favorite_movie">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" v-on:click="savePerson">Sauvegarder</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="closeModal">Quitter</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalUpdatePerson',
    emits: ['successfulUpdate'],
    data() {
        return {
            show: false,
            mutablePerson: null,
            fruits: null,
            colors: null,
            pets: null
        }
    },
    methods: {
        openModal(person) {
            this.show = true;
            this.mutablePerson = { ...person };
            this.getFavorites();
        },
        closeModal() {
            this.show = false;
        },
        getFavorites() {
            this.colors = this.$store.getters.getDataForFilter('preferences', 'favorite_color');
            this.fruits = this.$store.getters.getDataForFilter('preferences', 'favorite_fruit');
            this.pets = this.$store.getters.getDataForFilter('preferences', 'favorite_pet');
        },
        savePerson() {
            this.$store.dispatch('updatePerson', this.mutablePerson);
            this.closeModal();
            this.$emit('successfulUpdate');
        }
    }
}
</script>

<style>
    div.modal-dialog {
        max-width: 800px !important;
    }
</style>
