<template>
    <div class="container">
        <h1>Tableau de personnes</h1>

        <div class="container my-3">
            <button class="btn btn-success" @click="exportPeople()">Exporter (JSON)</button>
            <input v-model="searchTable" id="searchTable" class="mx-3" placeholder="Rechercher dans le tableau">
            <button class="btn btn-danger" @click="resetSearch()">Effacer la recherche</button>
        </div>

        <modal-update-person ref="modalUpdatePerson" v-on:successfulUpdate="successfulUpdate" />

        <table class="table table-dark">
            <thead>
                <tr>
                    <th>Actions</th>
                    <th>En savoir plus</th>
                    <th>ID</th>
                    <th>Prénom</th>
                    <th>Nom de famille</th>
                    <th>Sexe</th>
                </tr>
            </thead>
            <tbody>
                <table-row v-for="person in currentPeople" :key="person.id" :person="person" v-on:updatePerson="updatePerson">
                </table-row>
            </tbody>
        </table>

        <div id="pages" class="my-3">

        </div>
    </div>
</template>

<script>
import TableRow from '../components/TableRow.vue';
import ModalUpdatePerson from '../components/modals/ModalUpdatePerson';

export default {
    name: 'Table',
    components: {
        TableRow,
        ModalUpdatePerson
    },
    data() {
        return {
            people: null,
            currentPeople: [],
            filteredPeople: null,
            numberPages: 0,
            currentPage: 0,
            searchTable: '',
            person: null,
        }
    },
    mounted() {
        this.people = this.$store.getters.getPeople;
        this.manageButtonsPage();
    },
    methods: {
        createButtonPage(id) {
            let buttonPage = document.createElement('button');

            buttonPage.id = 'page' + id;
            buttonPage.innerHTML = 'Page ' + id;
            buttonPage.classList.add('btn', 'btn-primary', 'mx-2');

            buttonPage.addEventListener('click', () => {
                this.changePage(id)
            });

            return buttonPage;
        },
        changePage(id = 1) {
            // Filter people to get only 10 rows
            let data = this.filteredPeople || this.people;
            this.currentPage = id;
            this.currentPeople = data.filter((item) => {
                // Use index of object in array instead of its id
                let index = data.findIndex(obj => obj.id === item.id);
                return index <= (id * 10 - 1) && index >= (id * 10 - 10);
            });
        },
        manageButtonsPage() {
            // Use filtered people in priority
            let data = this.filteredPeople || this.people;
            this.numberPages = data.length / 10;

            // Empty button section
            let divPages = document.getElementById('pages');
            divPages.innerHTML = '';

            // Create buttons depending on the number of pages
            for (let i = 1; i <= this.numberPages; i++) {
                divPages.appendChild(
                    this.createButtonPage(i)
                );
            }

            // Display first page
            this.changePage();
        },
        resetSearch() {
            this.searchTable = '';
        },
        exportPeople() {
            // Convert people into JSON next into Blob
            const data = JSON.stringify(this.people);
            const blob = new Blob([data], {type: 'text/plain'})
            const e = document.createEvent('MouseEvents'),
            // Create and display popup to download JSON file
            a = document.createElement('a');
            a.download = "people.json";
            a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
            e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        },
        updatePerson(person) {
            this.$refs.modalUpdatePerson.openModal(person);
        },
        successfulUpdate() {
            this.people = this.$store.getters.getPeople;
            this.changePage(this.currentPage);
        }
    },
    watch: {
        searchTable: function(val) {
            if (val !== '') {
                // Filter people on all string fields
                this.filteredPeople = this.people.filter((item) => {
                    return item.firstname.toUpperCase().includes(val.toUpperCase())
                        || item.lastname.toUpperCase().includes(val.toUpperCase())
                        || item.gender.toUpperCase().includes(val.toUpperCase())
                        || item.contact.address.toUpperCase().includes(val.toUpperCase())
                        || item.contact.city.toUpperCase().includes(val.toUpperCase())
                        || item.contact.country.toUpperCase().includes(val.toUpperCase())
                        || item.contact.email.toUpperCase().includes(val.toUpperCase())
                        || item.contact.phone.includes(val)
                        || item.preferences.favorite_color.toUpperCase().includes(val.toUpperCase())
                        || item.preferences.favorite_fruit.toUpperCase().includes(val.toUpperCase())
                        || item.preferences.favorite_pet.toUpperCase().includes(val.toUpperCase());
                });
            } else {
                // Reset filtered data
                this.filteredPeople = null;
            }
            // Remove and recreate buttons compared to the filtered people or initial people
            this.manageButtonsPage();
        }
    }
}
</script>

<style>
    input#searchTable {
        width: 60%;
        height: 5vh;
    }
</style>
