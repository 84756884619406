<template>
    <tr>
        <td>
            <button type="button" class="btn btn-warning" @click="updatePerson(person)">Modifier</button>
        </td>
        <td><img src="@/assets/down.png" alt="En savoir plus" @click="findOutMore($event, person.id)" /></td>
        <td>{{ person.id }}</td>
        <td>{{ person.firstname }}</td>
        <td>{{ person.lastname }}</td>
        <td>{{ person.gender }}</td>
    </tr>
    <tr class="ghost" :id="createRowId(person.id)">
        <td colspan="7">
            <table class="table table-light table-bordered">
                <thead>
                    <tr>
                        <th>Adresse</th>
                        <th>Ville</th>
                        <th>Pays</th>
                        <th>Email</th>
                        <th>Numéro de téléphone</th>
                        <th>Coordonnées GPS</th>
                        <th>Couleur préférée</th>
                        <th>Fruit préféré</th>
                        <th>Animal préféré</th>
                        <th>Film préféré</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ person.contact.address }}</td>
                        <td>{{ person.contact.city }}</td>
                        <td>{{ person.contact.country }}</td>
                        <td>{{ person.contact.email }}</td>
                        <td>{{ person.contact.phone }}</td>
                        <td>{{ person.contact.location.lon }} / {{ person.contact.location.lat }}</td>
                        <td>{{ person.preferences.favorite_color }}</td>
                        <td>{{ person.preferences.favorite_fruit }}</td>
                        <td>{{ person.preferences.favorite_pet }}</td>
                        <td>{{ person.preferences.favorite_movie }}</td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'TableRow',
    props: ['person'],
    emits: ['updatePerson'],
    methods: {
        createRowId(id) {
            return 'personDetails' + id;
        },
        findOutMore(e, id) {
            // Get current image
            let element = e.target;
            // Get row with details
            let row = document.getElementById('personDetails' + id);
            let images = require.context('../assets/', false, /\.png$/);

            if (row.classList.contains('ghost')) {
                row.classList.remove('ghost');
                element.src = images('./up.png');
            } else {
                row.classList.add('ghost');
                element.src = images('./down.png');
            }
        },
        updatePerson(person) {
            this.$emit('updatePerson', person);
        }
    }
}
</script>

<style>
    img {
        height: 30px;
    }
    .ghost {
        display: none;
    }
</style>
